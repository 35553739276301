import { Injectable } from "@angular/core";
import { GoogleAuthProvider } from "firebase/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { LoginRequest } from "src/app/models/LoginRequest";
import { HttpClient } from "@angular/common/http";
import { ILoginResponse } from "../models/ILoginResponse";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient
  ) {}

  login() {
    return this.afAuth
      .signInWithPopup(new GoogleAuthProvider())
      .then((result) => {
        result.user
          ?.getIdToken()
          .then((accessToken) => {
            let loginRequest = new LoginRequest(accessToken!);
            this.http
              .post<ILoginResponse>(
                environment.baseUrl + "/authenticate/google",
                loginRequest.idToken
              )
              .subscribe(() => {
                this.router.navigate(["/"]);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  isLoggedIn() {
    return this.afAuth.currentUser.then((user) => user != null);
  }

  logOut() {
    this.afAuth.signOut().then(() => this.router.navigate(["/auth/login"]));
  }

  getCurrentUser() {
    return this.afAuth.currentUser;
  }

  getIdToken(): Observable<string | null> {
    return this.afAuth.idToken;
  }
}
