import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppLayoutModule } from "./layout/app.layout.module";
import { NotfoundComponent } from "./components/notfound/notfound.component";

import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { BrowserModule } from "@angular/platform-browser";
import { AuthService } from "./services/auth.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HeadersInterceptor } from "./shared/headers.interceptor";
import { DialogService } from "primeng/dynamicdialog";

@NgModule({
  declarations: [AppComponent, NotfoundComponent],
  imports: [
    AppLayoutModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    AuthService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
    ConfirmationService,
    DialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
