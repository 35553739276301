<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/bewire-logo.png" alt="logo">
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="showProfileSidebar()">
        <p-avatar class="layout-topbar-button" shape="circle" image="{{ image }}"></p-avatar>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible}">
        <p-avatar class="layout-topbar-button" [ngClass]="{'hidden': layoutState.profileSidebarVisible}" shape="circle" image="{{ image }}"></p-avatar>
        <button class="p-link layout-topbar-button" (click)="logOut()">
            <i class="pi pi-sign-out"></i>
            <span>Log out</span>
        </button>
    </div>

    
</div>