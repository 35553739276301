import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { NotfoundComponent } from "./components/notfound/notfound.component";
import { AppLayoutComponent } from "./layout/app.layout.component";
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from "@angular/fire/compat/auth-guard";

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(["auth/login"]);

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "",
          component: AppLayoutComponent,
          canActivate: [AngularFireAuthGuard],
          data: { authGuardPipe: redirectUnauthorizedToLogin },
          children: [
            {
              path: "",
              loadChildren: () =>
                import("./components/pages/empty/empty.module").then(
                  (m) => m.EmptyModule
                ),
            },
            {
              path: "preboarding",
              children: [
                {
                  path: "preboarders",
                  loadChildren: () =>
                    import(
                      "./components/pages/preboarders/preboarders.module"
                    ).then((m) => m.PreboardersModule),
                },
                {
                  path: "activities",
                  loadChildren: () =>
                    import(
                      "./components/pages/activities/activities.module"
                    ).then((m) => m.ActivitiesModule),
                },
                {
                  path: "templates",
                  loadChildren: () =>
                    import(
                      "./components/pages/templates/templates.module"
                    ).then((m) => m.TemplatesModule),
                },
              ],
            },
          ],
        },
        {
          path: "auth",
          loadChildren: () =>
            import("./components/auth/auth.module").then((m) => m.AuthModule),
        },
        { path: "notfound", component: NotfoundComponent },
        { path: "**", redirectTo: "/notfound" },
      ],
      {
        scrollPositionRestoration: "enabled",
        anchorScrolling: "enabled",
        onSameUrlNavigation: "reload",
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
