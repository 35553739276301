export default
{
  apiKey: "AIzaSyB1E6IlocWh0d5UHkfjM_u7N2qt2uEYltA",
  authDomain: "who-is-who-270620.firebaseapp.com",
  databaseURL: "https://who-is-who-270620.firebaseio.com",
  projectId: "who-is-who-270620",
  storageBucket: "who-is-who-270620.appspot.com",
  messagingSenderId: "493239961951",
  appId: "1:493239961951:web:4c045b8fc04d9bd3feceea",
  measurementId: "G-6B9ZN99DQ7",
}
;
