import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MenuItem } from "primeng/api";
import { AuthService } from "src/app/services/auth.service";
import { LayoutService, LayoutState } from "../service/app.layout.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.component.html",
})
export class AppTopBarComponent implements OnInit {
  items!: MenuItem[];
  image: String | null = "";

  @ViewChild("menubutton") menuButton!: ElementRef;

  @ViewChild("topbarmenubutton") topbarMenuButton!: ElementRef;

  @ViewChild("topbarmenu") menu!: ElementRef;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user) => {
      this.image = user!.photoURL;
    });
  }

  logOut() {
    this.authService.logOut();
  }

  onMenuToggle() {
    this.layoutService.onMenuToggle();
  }

  showProfileSidebar() {
    this.layoutService.showProfileSidebar();
  }

  get layoutState(): LayoutState {
    return this.layoutService.state;
  }
}
