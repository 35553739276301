import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, pipe, switchMap, take, throwError } from "rxjs";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthService } from "../services/auth.service";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.authService.logOut();

      this.messageService.add({
        severity: "error",
        summary: "Authorization error",
        detail: err.message,
      });

      return of(err.message);
    }
    this.messageService.add({
      severity: "error",
      summary: "An unknown error occurred",
      detail: err.message,
    });
    return throwError(() => new Error(err.message));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getIdToken()
      .pipe(
        take(1),
        switchMap((token: string | null) => {
          if (token) {
            req = req.clone({
              setHeaders: {
                Authorization: "Bearer " + token,
              },
            });
          }
          return next.handle(req)
            .pipe(catchError((err) => this.handleAuthError(err)));
        }));
  }
}
