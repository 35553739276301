import { OnInit } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor() {}

  ngOnInit() {
    this.model = [
      {
        label: "Menu",
        items: [
          {
            label: "Preboarding",
            items: [
              {
                label: "All Preboarders",
                icon: "pi pi-fw pi-user",
                routerLink: ["/preboarding/preboarders"],
              },
              {
                label: "Activities",
                icon: "pi pi-fw pi-calendar",
                routerLink: ["/preboarding/activities"],
              },
              {
                label: "Templates",
                icon: "pi pi-fw pi-table",
                routerLink: ["/preboarding/templates"],
              },
            ],
          },
        ],
      },
    ];
  }
}
